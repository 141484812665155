import Head from 'next/head';
import { usePage } from '~/services/cms';
import { isSSR } from '~/shared/utils';


export const MetaFields = () => {
    const { data: page } = usePage();
    const { title, description, canonical, hideFromRobots } = page?.meta || {};
    let metaViewport = "initial-scale=1.0, width=device-width, user-scalable=no";
    if (!isSSR && navigator.userAgent.indexOf('iPhone') > -1) {
        metaViewport = "width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0";
    }

    return (
        <Head>
            <meta charSet="utf-8" />
            <meta name="viewport" content={metaViewport} />
            {title ? <title>{title}</title> : null}
            {description ? <meta name="description" content={description} /> : null}
            {hideFromRobots ? <meta name="robots" content="noindex" /> : null}
            {canonical ? <link rel="canonical" href={canonical.url} /> : null}
            {page?.hrefLangs.map(({ culture, url }) => (
                <link
                    rel="alternate"
                    key={`hreflang-${culture}`}
                    hrefLang={`${culture}`}
                    href={url}
                />
            ))}
        </Head>
    );
};
