import { Text, Link, Skeleton, Image } from 'ui';
import AddToBasket from '$features/Basket/components/AddToBasket/AddToBasket';
import styles from './Card.module.css'

import React, { FC } from 'react';
import { generatePDPLink } from '~/shared/utils';
import NextLink from 'next/link';
import BaseProductInfo from '~/components/shared/BaseProductInfo/BaseProductInfo';
import { useTranslations } from '~/services/cms';
import { CustomerPrice, RelewiseProduct, StockStatus } from '~/components/features';
import AddFavoriteButton from '~/components/features/Favorites/AddFavoriteButton/AddFavoriteButton';
import { useAuthentication } from '~/shared/hooks';
import { isJPG, isPorsche } from 'utils';
import splashImageNew from '$assets/splash-new.svg';
export const VEHICLE_TYPE_NUMBER_KEY = 'vehicle-type-number'

export const SkeletonCard = () => (
    <div className={styles.root}>
        <section className={styles.skeletonImageContainer}>
            <Skeleton width='75px' height='75px' />
            <div>
                <Skeleton width='90%' height='20px' />
                <br />
                <Skeleton width='60%' height='15px' />
            </div>
        </section>
        <section className={styles.addToBasketSection}>
            <div className={styles.price}>
                <Skeleton width='140px' height='35px' />
            </div>
            <div className={styles.stockStatus}>
                <Skeleton width='100px' height='20px' />
            </div>
            <div className={styles.addToBasket}>
                <Skeleton width='170px' height='40px' />
            </div>
        </section>
        <section>
            <div className={styles.partCriteriasContainer}>
                <Skeleton width='100%' height='100px' />
                <Skeleton width='120px' height='20px' />
            </div>
        </section>
    </div>
)

interface IProps {
    product: RelewiseProduct
    selectedVehicleTypeNumber?: number | null;
}

export const Card: FC<IProps> = ({ product, selectedVehicleTypeNumber }) => {
    const translate = useTranslations()
    const { isAuthenticated } = useAuthentication();


    if (!product) return null
    const { displayName, data, productId, brand } = product || {};
    const isNewProduct = product?.data?.Remark?.value?.toLowerCase() === 'new'

    const generatedPDPLink = generatePDPLink({
        productId,
        variantId: productId,
        displayName: displayName || 'product'
    })

    const PDPLink = selectedVehicleTypeNumber ? `${generatedPDPLink}?${VEHICLE_TYPE_NUMBER_KEY}=${selectedVehicleTypeNumber}` : generatedPDPLink

    const partCriterias = data?.PartCriterias?.value?.$values.length
        ? data?.PartCriterias?.value?.$values
            ?.map(({ data }) => (data))
            ?.sort((a, b) => Number(a?.SortNo?.value) - Number(b?.SortNo?.value))
            ?.slice(0, 3)
        : []


    const isJPGroup = isJPG() || isPorsche()
    const showPrices = !isJPGroup || isAuthenticated


    return (
        <div className={styles.root}>
            <NextLink
                passHref
                aria-label={displayName}
                title={displayName}
                href={PDPLink}>
                <a href={PDPLink} className={styles.productLink}>
                    <div className={styles.splashesContainer}>
                        {isNewProduct && (
                            <Image src={splashImageNew} objectFit="contain" />
                        )}
                    </div>
                    <BaseProductInfo
                        imgSrc={data?.PictureWebFull?.value}
                        productName={displayName}
                        jpGroupNumber={data?.JpGroupNumber?.value}
                        oeNumber={data?.OldJPNumber?.value}
                        brand={brand?.displayName}
                        suitableForExpress={data?.SuitableForExpres?.value.toLowerCase() === 'yes' ? 'yes' : 'no'}
                    >
                        <AddFavoriteButton productId={productId} className={styles.addFavoriteBtn} />
                    </BaseProductInfo>
                </a>
            </NextLink>


            {showPrices && (
                <section className={styles.addToBasketSection}>
                    <div className={styles.price}>
                        <CustomerPrice
                            className={styles.price}
                            variantId={productId}
                            packingUnit={data?.PackingUnit?.value}
                            unit={data?.Unit?.value}
                            showPriceOffers
                        />
                    </div>
                    <div className={styles.stockStatus}>
                        <StockStatus
                            productId={productId}
                            statusCode={isJPGroup ? data?.JpgTrafficLight?.value : data?.HowTrafficLight?.value}
                            amount={isJPGroup ? data?.ShowJpStock?.value : data?.HowStock?.value}
                            expectedDeliveryDate={data?.ExpectedDate?.value}
                        />
                    </div>
                    <div className={styles.addToBasket}>
                        <AddToBasket
                            small
                            productId={productId}
                            variantId={productId}
                            colli={Number(data?.PackingUnit?.value || 1)}
                            hideButtonText />
                    </div>
                </section>
            )}

            <section>
                <div className={styles.partCriteriasContainer}>
                    {partCriterias?.length ? (
                        <>
                            <ul className={styles.partCriterias}>
                                {partCriterias?.map((item, i) => (
                                    <li className={styles.linkageItem}
                                        key={`${item?.CritNo?.value}-${i}`}>
                                        <Text variant='bodyExtraSmall' color='natural700Color'>
                                            {item?.CriteriaDesc?.value}
                                        </Text>
                                        <Text variant='bodyExtraSmall'>
                                            {item?.CriteriaValueDesc?.value}
                                        </Text>
                                    </li>
                                ))}
                            </ul>
                            <NextLink href={PDPLink}>
                                <Link href={PDPLink} underline>
                                    {translate('product.seeAllReferences')}
                                </Link>
                            </NextLink>
                        </>
                    ) : null}

                </div>
            </section >
        </div >
    );
};
