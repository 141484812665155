import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'
import { format } from 'url'
import { ParsedUrlQuery } from 'querystring'

export function getRouterQueryParamsWithoutPagination(
    routerParams: Record<string, any>
) {
    // Copies the router params
    const copiedRouterParams = { ...routerParams }

    // Removes the slug from the params
    delete copiedRouterParams.slug

    // Removes the page from the params
    delete copiedRouterParams.page

    return copiedRouterParams
}


// Query string prefix used for all facet keys
export const facetQueryKeyPrefix = 'f.'

export interface IActiveFacet {
    key: string;
    values: string[];
}

export interface ISetFacetPayload {
    facetKey: string,
    facetValue: string
}


const useFacets = () => {
    const [activeFacets, setActiveFacets] = useState<IActiveFacet[]>([])

    const router = useRouter()

    const routerParams = getRouterQueryParamsWithoutPagination(router.query)


    function setMultipleFacets(payload: ISetFacetPayload[]) {

        let newRouterParams = { ...routerParams }

        payload.forEach(({ facetKey, facetValue }) => {
            const facetQueryKey = `${facetQueryKeyPrefix}${facetKey}`

            let values = routerParams[facetQueryKey] as string[] || []
            if (!Array.isArray(values)) {
                values = [values]
            }

            // Add value or remove it if it already exists
            const existingValueIndex = values.findIndex(value => value === facetValue)

            if (existingValueIndex > -1) {
                values.splice(existingValueIndex, 1)
            } else {
                values.push(facetValue)
            }

            newRouterParams[facetQueryKey] = values

            // Complete remove query param if no values left or no facetValue
            if (values.length === 0 || !facetValue) {
                delete newRouterParams[facetQueryKey]
            }
        })

        const hasHash = window.location.hash && window.location.hash.length > 0;

        const url = hasHash ? format({
            pathname: window.location.pathname,
            query: newRouterParams,
            hash: window.location.hash
        }) : format({
            pathname: window.location.pathname,
            query: newRouterParams,
        })

        router.replace(
            url,
            undefined,
            { shallow: true, scroll: false }
        )
    }

    function setFacet(facetKey: string, facetValue: string) {
        const facetQueryKey = `${facetQueryKeyPrefix}${facetKey}`

        let values = routerParams[facetQueryKey] as string[] || []
        if (!Array.isArray(values)) {
            values = [values]
        }


        // Add value or remove it if it already exists
        const existingValueIndex = values.findIndex(value => value === facetValue)

        if (existingValueIndex > -1) {
            values.splice(existingValueIndex, 1)
        } else {
            values.push(facetValue)
        }

        const queryParams = {
            ...routerParams,
            [facetQueryKey]: values
        }

        // Complete remove query param if no values left or no facetValue
        if (values.length === 0 || !facetValue) {
            delete queryParams[facetQueryKey]
        }

        const hasHash = window.location.hash && window.location.hash.length > 0;

        const url = hasHash ? format({
            pathname: window.location.pathname,
            query: queryParams,
            hash: window.location.hash
        }) : format({
            pathname: window.location.pathname,
            query: queryParams,
        })

        router.replace(
            url,
            undefined,
            { shallow: true, scroll: false }
        )
    }

    function getActiveFacets(query: ParsedUrlQuery): IActiveFacet[] {
        if (!query) return []

        return Object.entries(routerParams)
            .filter(param => param[0].startsWith(facetQueryKeyPrefix))
            .map(param => ({
                key: param[0].slice(2),
                values: Array.isArray(param[1])
                    ? param[1]
                    : [param[1]]
            }) as IActiveFacet)
    }


    function clearFacets(facets?: string[]) {
        const copyOfRouterParams = { ...routerParams }

        if (facets) {
            facets?.forEach(facet => {
                delete copyOfRouterParams[`${facetQueryKeyPrefix}${facet}`]
            })
        } else {
            Object.keys(copyOfRouterParams).forEach(key => {
                if (!key.startsWith(facetQueryKeyPrefix)) return
                delete copyOfRouterParams[key]
            })
        }

        const hasHash = window.location.hash && window.location.hash.length > 0;

        const url = hasHash ? format({
            pathname: window.location.pathname,
            query: copyOfRouterParams,
            hash: window.location.hash
        }) : format({
            pathname: window.location.pathname,
            query: copyOfRouterParams,
        })

        router?.push(url, undefined, { shallow: true })
    }

    const setCategoryHierarchyFacet = (parentId: string, childId: string) => {
        const facetQueryKey = `${facetQueryKeyPrefix}CategoryHierarchy`;

        let values = routerParams[facetQueryKey] as string[] || []
        if (!Array.isArray(values)) {
            values = [values]
        }

        // Add value or remove it if it already exists
        const existingValueIndex = values.findIndex(value => value === `${parentId}-${childId}`)

        if (existingValueIndex > -1) {
            values.splice(existingValueIndex, 1)
        } else {
            values.push(`${parentId}-${childId}`)
        }

        const queryParams = {
            ...routerParams,
            [facetQueryKey as string]: values
        }

        // Complete remove query param if no values left or no facetValue
        if (values.length === 0 || !parentId || !childId) {
            delete queryParams[facetQueryKey]
        }

        const hasHash = window.location.hash && window.location.hash.length > 0;

        const url = hasHash ? format({
            pathname: window.location.pathname,
            query: queryParams,
            hash: window.location.hash
        }) : format({
            pathname: window.location.pathname,
            query: queryParams,
        })

        router.replace(
            url,
            undefined,
            { shallow: true, scroll: false }
        )
    }

    useEffect(() => {
        if (!router.query) return
        const facets = getActiveFacets(router.query)
        setActiveFacets(facets)
    }, [router])

    const activeFacetsLength = activeFacets.map(f => f.values).flat().length


    return {
        activeFacets,
        activeFacetsLength,
        setFacet,
        setMultipleFacets,
        setCategoryHierarchyFacet,
        clearFacets
    }

}

export default useFacets

