import React, { FC } from 'react'
import styles from './BaseProductInfo.module.css'
import fallbackImage from '$assets/fallback-product.svg';
import { Image, Text } from 'ui';
import { useTranslations } from '~/services/cms';

interface IProps {
    productName?: string;
    imgSrc?: string;
    jpGroupNumber?: string;
    oeNumber?: string;
    className?: string;
    brand?: string;
    suitableForExpress?: 'yes' | 'no';
    children?: React.ReactNode;
}

const BaseProductInfo: FC<IProps> = (props) => {
    const { productName, imgSrc, jpGroupNumber, oeNumber, brand, suitableForExpress, children } = props
    const translate = useTranslations();

    return (
        <div className={styles.root}>
            <Image
                src={imgSrc || fallbackImage}
                alt={productName}
                width={75}
                height={75}
                objectFit="cover"
            />

            <div className={styles.description}>
                <Text variant='display6' as='h2' className={styles.productName}>
                    {productName}
                </Text>
                {jpGroupNumber && (
                    <div className={styles.flexInfo}>
                        <Text nowrap variant="bodyExtraSmall">
                            {translate('product.jpGroupNr')}:
                        </Text>
                        <Text nowrap variant="bodyExtraSmall" color='natural700Color'>
                            {jpGroupNumber}
                        </Text>
                    </div>
                )}
                {oeNumber && (
                    <div className={styles.flexInfo}>
                        <Text nowrap variant="bodyExtraSmall">
                            {translate('product.oeNumber')}:
                        </Text>
                        <Text nowrap variant="bodyExtraSmall" color='natural700Color'>
                            {oeNumber}
                        </Text>
                    </div>
                )}
                {brand && (
                    <div className={styles.flexInfo}>
                        <Text nowrap variant="bodyExtraSmall">
                            {translate('product.brand')}:
                        </Text>
                        <Text nowrap variant="bodyExtraSmall" color='natural700Color'>
                            {brand}
                        </Text>
                    </div>
                )}
                {suitableForExpress && (
                    <Text variant='bodyExtraSmall' className={styles.expressInfo} fontWeight='semiBold'>
                        {suitableForExpress === 'yes'
                            ? translate('product.expressShipping')
                            : translate('product.expressShippingFalse')
                        }
                    </Text>
                )}
                {children}
            </div>
        </div>
    )
};

export default BaseProductInfo