import { FC } from 'react';
import { Button, Text, Tooltip, TooltipContentWrapper } from 'ui';
import { IStatusCodeColor } from '..';
import { useTranslations } from '~/services/cms';
import styles from './StockStatus.module.css';
import { useWatchlist } from '~/page-type/page-types/P181Profile';
import { useAuthentication } from '~/shared/hooks';

interface IProps {
    statusCode?: IStatusCodeColor;
    amount?: string;
    expectedDeliveryDate?: string;
    productId: string;
}

export const StockStatus: FC<IProps> = ({ statusCode, amount, expectedDeliveryDate, productId }) => {
    const { isAuthenticated } = useAuthentication();
    const translate = useTranslations()
    const { isInTheList, watchlistMutation } = useWatchlist();

    if (typeof (statusCode) === undefined) return null;

    const getLabel = (): string => {
        switch (statusCode) {
            case '0':
                return translate('stockStatus.notAvailable')

            case '1':
                return translate('stockStatus.availableAmount')
                    .replace('[amount]', amount || '')

            case '2':
                return translate('stockStatus.shippingWarehouse')

            case '3':
                return expectedDeliveryDate
                    ? `${translate('stockStatus.expectedDelivery')}: ${expectedDeliveryDate?.split(' ')?.[0]}`
                    : translate('stockStatus.callForInfo')

            default:
                return ''
        }
    }

    return (
        <div className={styles.root}>
            <span className={styles[`status${statusCode}`]} />
            <Text
                className={styles[`textColor${statusCode}`]}
                as='span'
                variant='bodyExtraSmall'
                fontWeight='light'
                nowrap>
                {getLabel()}
            </Text>
            {statusCode === '0' && isAuthenticated && (
                <Tooltip
                    content={
                        <TooltipContentWrapper>
                            <Text variant='bodyExtraSmall'>
                                {isInTheList(productId) ?
                                    translate('stockStatus.removeFromStockNotification') :
                                    translate('stockStatus.addToStockNotification')}
                            </Text>
                        </TooltipContentWrapper>
                    }>
                    <Button
                        size='small'
                        variant='link'
                        iconName={isInTheList(productId) ? 'bell-on' : 'bell-off'}
                        className={styles.watchStock}
                        onClick={() => watchlistMutation.mutate(productId)} />
                </Tooltip>
            )}
        </div>
    );
};