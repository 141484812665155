import React, { FC, useState } from 'react'
import { Button } from 'ui';
import { relewiseFetcher, RelewiseProduct, IFetchRelewiseProps } from '~/components/features';
import * as XLSX from 'xlsx';
import { useRouter } from 'next/router';
import { useNotification } from '~/shared/hooks/useNotification';
import useFacets from '~/components/features/ProductsList/Facets/use-facets';
import { useUserInformation } from '~/page-type/page-types/P181Profile';
// import { generatePDPLink } from '~/shared/utils';
// import { isJPG } from 'utils';

// const isJPGroup = isJPG()

// import styles from './Component.module.scss'

type Props = Omit<IFetchRelewiseProps, 'activeFacets' | 'disallowedProductsGroups' | 'locale'> & {
    searchQuery?: string;
    vehicleTypeNumberFilter?: number[];
    totalProductsCount?: number;
}

const DownloadProductList: FC<Props> = (props) => {
    const { searchQuery, vehicleTypeNumberFilter, totalProductsCount = 10000 } = props

    const [isFetching, setIsFetching] = useState(false)

    const { push } = useNotification();
    const { locale, defaultLocale } = useRouter();
    const { data: userInformation } = useUserInformation()
    const { activeFacets } = useFacets()

    const disallowedProductsGroups = userInformation?.disallowedProductsGroups || []

    const mapData = (data: RelewiseProduct[]) => {



        return data?.map(product => {
            const { displayName, data } = product

            // const PDPLink = generatePDPLink({
            //     productId,
            //     variantId: productId,
            //     displayName: displayName || 'product'
            // })

            return ({
                JPGroupNumber: data?.JpGroupNumber?.value,
                DisplayName: displayName,
                // Remark: data?.Remark?.value,
                // NewMonth: data?.NewMonth?.value,
                // Brand: brand.displayName,
                // PackingUnit: data?.PackingUnit?.value,
                // Availability: isJPGroup ? data?.ShowJpStock?.value : data?.HowStock?.value,
                // Image: data?.PictureWebFull?.value,
                // ProductLink: PDPLink,
            })
        })
    }



    const downloadFile = async () => {
        setIsFetching(true)
        try {
            const data = await relewiseFetcher({
                locale: locale || defaultLocale || 'en',
                searchQuery,
                activeFacets,
                pageSize: totalProductsCount,
                vehicleTypeNumberFilter,
                disallowedProductsGroups

            })
            downloadExcel(mapData(data?.results as RelewiseProduct[]))
        } catch (error) {
            push({ severity: 'warning', text: 'Something went wrong' })
        } finally {
            setIsFetching(false)
        }
    }


    const downloadExcel = (data: any[]) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "DataSheet.xls");
    };



    return (
        <div>
            <Button onClick={() => downloadFile()} isLoading={isFetching} iconName='download' size='small' variant='secondary' />
        </div>
    )
}

export default DownloadProductList