import React, { FC, useRef } from 'react'
import { Skeleton, Text } from 'ui'
import { useTranslations } from '~/services/cms';
import { useCustomerPrices } from './hooks';
import styles from './CustomerPrice.module.css'
import clsx from 'clsx';
import ErrorBox from '~/components/shared/ErrorBox/ErrorBox';
// import { useOnScreen } from 'utils';
import { isJPG, isPorsche } from 'utils';
import PriceOffers from '../PriceOffers/PriceOffers';

const isJPGroup = isJPG() || isPorsche;

interface IProps {
    variantId: string;
    packingUnit: number | string;
    salesPrice?: string;
    uniPrice?: string;
    unit?: string;
    className?: string;
    alignItems?: 'left' | 'right'
    isLarge?: boolean
    showPriceOffers?: boolean;
}

export const CustomerPrice: FC<IProps> = (props) => {
    const {
        packingUnit,
        salesPrice,
        uniPrice,
        unit,
        variantId,
        className,
        alignItems = 'right',
        isLarge,
        showPriceOffers
    } = props


    const ref = useRef<HTMLDivElement>(null);
    // const isVisible = useOnScreen(ref, { rootMargin: '1000px' });

    const shouldFetch = !salesPrice // && isVisible
    const { data, isLoading, isError } = useCustomerPrices(variantId ? [variantId] : [], shouldFetch)
    const translate = useTranslations()

    const asyncPrice = data?.[0] || undefined
    const displayPrice = salesPrice || asyncPrice?.taxedPrice;
    const displayUnitPrice = uniPrice || asyncPrice?.unitPrice;

    const classNames = clsx(styles.root, className, styles[`${alignItems}Align`])

    function renderContent() {
        if (shouldFetch && isLoading) return (
            <>
                <Skeleton
                    width='120px' height={isLarge ? '25px' : '22px'}
                    className={isLarge ? styles.skeletonPriceLarge : styles.skeletonPrice} />
                {isJPGroup && <Skeleton width='100px' height='13px' />}

            </>
        )

        if (isError) return (
            <ErrorBox />
        )

        return (
            <>
                <Text
                    as="div"
                    fontWeight='semiBold'
                    variant={isLarge ? "display5" : "display6"}>
                    {displayPrice} {unit && `/ ${unit}`}
                </Text>
                {showPriceOffers && (
                    <PriceOffers values={asyncPrice?.priceOffers} />
                )}
                {isJPGroup && (
                    <>
                        <Text as='div' variant='bodyExtraSmall' >
                            {translate('product.packageUnit')} {packingUnit}
                        </Text>
                    </>
                )}
                {!isJPGroup && (
                    <>
                        <Text
                            as="div"
                            fontWeight='regular'
                            color='natural700Color'
                            variant={isLarge ? "bodySmall" : "bodyExtraSmall"}>
                            {displayUnitPrice} {unit && `/ ${unit}`}
                        </Text>
                        <Text
                            as='div'
                            fontWeight='regular'
                            color='natural700Color'
                            variant={isLarge ? "bodySmall" : "bodyExtraSmall"}>
                            {translate('product.withoutVat')}
                        </Text>
                    </>
                )}
            </>
        )
    }

    return (
        <div className={classNames} ref={ref}>
            {renderContent()}
        </div >
    )
}
