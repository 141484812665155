import React, { useState } from 'react';
import { ModuleContainer, TabButton, Tabs, Text } from 'ui';
import { OrderHistory } from './components/OrderHistory/OrderHistory';
import PageHeaderContainer from '~/components/shared/PageHeaderContainer/PageHeaderContainer';
import { OverviewPage, WatchList } from './components';
import { useTranslations } from '~/services/cms';
import { useAuthentication } from '~/shared/hooks';

enum ProfileTabsContent {
    Overview,
    OrderHistory,
    StockStatus,
}

export const P181ProfilePage = () => {
    const [activeTab, setActiveTab] = useState<ProfileTabsContent>(ProfileTabsContent.Overview);
    const { isAuthenticating, isAuthenticated, session } = useAuthentication();
    const translate = useTranslations();

    if (isAuthenticating) {
        return null;
    }

    if (!session || !isAuthenticated) {
        return (
            <ModuleContainer hasGutter>
                <Text variant='display2'>{translate('auth.unauthenticated.title')}</Text>
                <Text variant='body'>{translate('auth.unauthenticated.title')}</Text>
            </ModuleContainer>
        )
    }

    return (
        <>
            <ModuleContainer hasGutter hasPadding={false}>
                <div>
                    <Tabs>
                        <TabButton
                            isActive={activeTab === ProfileTabsContent.Overview}
                            onClick={() => setActiveTab(ProfileTabsContent.Overview)}
                        >
                            {translate('profile.overview')}
                        </TabButton>
                        <TabButton
                            isActive={activeTab === ProfileTabsContent.OrderHistory}
                            onClick={() => setActiveTab(ProfileTabsContent.OrderHistory)}
                        >
                            {translate('profile.myOrders')}
                        </TabButton>
                        <TabButton
                            isActive={activeTab === ProfileTabsContent.StockStatus}
                            onClick={() => setActiveTab(ProfileTabsContent.StockStatus)}
                        >
                            {translate('profile.stockWatchlist')}
                        </TabButton>
                    </Tabs>
                </div>
            </ModuleContainer>
            <PageHeaderContainer>
                {activeTab === ProfileTabsContent.OrderHistory ? (
                    <OrderHistory />
                ) : activeTab === ProfileTabsContent.StockStatus ? (
                    <WatchList />
                ) : (
                    <OverviewPage />
                )}
            </PageHeaderContainer>
        </>
    );
};
