import React, { memo } from 'react';
import { StyledSummaryContent, StyledSummaryItem, StyledSummaryItemLink } from './styled';
import { PriceGroup } from '~/components/features';
import { Text, SkeletonDeprecated, Image } from 'ui';
import NextLink from 'next/link';
import { ConditionalWrap, Visibility } from '~/shared/utils/jsx';
import { useTranslations } from '~/services/cms';
import { CommerceModel } from 'api-types';
import { generateImageUrl } from '~/shared/utils';

export type Props = {
    line: CommerceModel.LineItem;
};

export const SummaryItem = memo(({ line }: Props) => {
    const translate = useTranslations();
    const { images, name, brand, productId, quantity = 1, priceOutput, url } = line;
    const separator = brand && productId ? ' - ' : '';

    const imageUrl = images && images?.length > 0 ? generateImageUrl({
        size: 'productcarousel',
        fileName: images[0]?.fileName || '',
        modifiedDateTime: images[0]?.modifiedDateTime || ''
    }) : undefined;

    const imageSize = {
        height: 76,
        width: 62,
    };

    return (
        <StyledSummaryItem>
            <ConditionalWrap
                if={Boolean(url)}
                wrap={(children) => (
                    <NextLink href={url || ''} passHref>
                        <StyledSummaryItemLink children={children} />
                    </NextLink>
                )}
            >
                {imageUrl ? (
                    <Image src={imageUrl} {...imageSize} layout="intrinsic" objectFit="contain" />
                ) : (
                    <SkeletonDeprecated {...imageSize} />
                )}
            </ConditionalWrap>

            <StyledSummaryContent>
                <ConditionalWrap
                    if={Boolean(url)}
                    wrap={(children) => (
                        <NextLink href={url || ''} passHref>
                            <StyledSummaryItemLink children={children} />
                        </NextLink>
                    )}
                >
                    <Text children={name} variant="body" />
                </ConditionalWrap>
                <Text
                    variant="caption"
                    children={`${brand ? brand : ''}${separator}${productId ? productId : ''}`}
                />

                <Text
                    children={translate('commerce.basket.quantityDescribed', [{ key: 'quantity', value: quantity }])}
                    variant="caption"
                />

                <Visibility.HiddenFrom md>
                    <PriceGroup
                        displayPriceInclTax={priceOutput?.totalPrice?.displayTaxedPrice}
                        displaySalePriceInclTax={priceOutput?.salePrice?.displayTaxedPrice}
                        displayUnitPriceInclTax={priceOutput?.salePrice ? priceOutput?.salePrice?.displayTaxedPrice : priceOutput?.unitPrice?.displayTaxedPrice}
                        isOnSale={priceOutput?.salePrice !== null} variant="body" />
                </Visibility.HiddenFrom>
            </StyledSummaryContent>

            <StyledSummaryContent justifySelf="flex-end">
                <Visibility.VisibleFrom md>
                    <PriceGroup
                        displayPriceInclTax={priceOutput?.totalPrice?.displayTaxedPrice}
                        displaySalePriceInclTax={priceOutput?.salePrice?.displayTaxedPrice}
                        displayUnitPriceInclTax={priceOutput?.salePrice ? priceOutput?.salePrice?.displayTaxedPrice : priceOutput?.unitPrice?.displayTaxedPrice}
                        isOnSale={priceOutput?.salePrice !== null} variant="body" orientation="vertical" />
                </Visibility.VisibleFrom>
            </StyledSummaryContent>
        </StyledSummaryItem>
    );
});
