import { Skeleton, Text, getSkeletonRandom, Accordion, AccordionItem } from 'ui';

import styles from './SidebarFilter.module.css';
import { useTranslations } from '~/services/cms';
import { CategoryHierarchyFacetResult, CategoryHierarchyFacetResultCategoryNode } from '@relewise/client';
import { FC, useMemo } from 'react';
import useFacets from '~/components/features/ProductsList/Facets/use-facets';
import { Checkbox } from 'ui';

interface IProps {
    facets?: CategoryHierarchyFacetResult
    isFetching?: boolean;
    isLoading?: boolean;
    searchQuery?: string;
}

const SkeletonListItem = () => {
    const width = useMemo(() => getSkeletonRandom(60), [])

    return (
        <li className={styles.skeletonListItem}>
            <Skeleton
                width={`${width}%`} height='15px'
            />
            <Skeleton
                width='25px' height='15px'
            />
        </li>
    )
}

const SkeletonList = () => (
    <>
        {
            Array.from(Array(20).keys())
                .map((item) => (
                    <SkeletonListItem key={item} />
                ))
        }
    </>
)

export const SidebarFilter: FC<IProps> = ({ facets, isFetching, isLoading, searchQuery = '' }) => {
    const translate = useTranslations()
    const { setCategoryHierarchyFacet } = useFacets()

    return (
        <div>
            <div className={styles.header}>
                <Text variant="body">
                    {translate('product.productCategories')}
                </Text>
            </div>
            <Accordion type='single'>
                {isLoading
                    ? <SkeletonList />
                    : facets?.nodes?.sort((a: CategoryHierarchyFacetResultCategoryNode, b: CategoryHierarchyFacetResultCategoryNode) => {
                        if (!a?.category?.displayName || !b?.category?.displayName)
                            return 0;

                        if (a?.category?.displayName < b?.category?.displayName) {
                            return -1;
                        }
                        if (a?.category?.displayName > b?.category?.displayName) {
                            return 1;
                        }
                        return 0;
                    }).map((f, index) => {
                        const selectedChilds = f.children?.filter(option => option.selected) || [];
                        return (
                            <AccordionItem
                                key={`${index}-${f?.category?.categoryId}`}
                                className={styles.listItem}
                                header={
                                    <div className={styles.sidebarCategoryHeader}>
                                        <Text
                                            text={f.category.displayName || ''}
                                            title={f.category.displayName || ''}
                                            highlightText={searchQuery}
                                            overflowEllipsis
                                            fontWeight={f.selected ? 'bold' : 'regular'}
                                            variant="body"
                                        />
                                        {selectedChilds.length > 0 ? <Text
                                            color='grey'
                                        >({selectedChilds.length} {translate('facets.quantitySelected')})</Text> : null}
                                    </div>}
                            >
                                <ul className={styles.subList}>
                                    {f.children?.sort((a: CategoryHierarchyFacetResultCategoryNode, b: CategoryHierarchyFacetResultCategoryNode) => {
                                        if (!a?.category?.displayName || !b?.category?.displayName)
                                            return 0;

                                        if (a?.category?.displayName < b?.category?.displayName) {
                                            return -1;
                                        }
                                        if (a?.category?.displayName > b?.category?.displayName) {
                                            return 1;
                                        }
                                        return 0;
                                    }).map((option, index) => (
                                        <li className={styles.subListItem} key={`${index}-${option.category.categoryId}`}>
                                            <button
                                                key={index}
                                                disabled={isFetching}
                                                className={styles.button}
                                                onClick={() => setCategoryHierarchyFacet(
                                                    f.category.categoryId as string,
                                                    option.category.categoryId as string)
                                                }>
                                                <Checkbox checked={option.selected}></Checkbox>
                                                <Text
                                                    className={styles.subCategoryText}
                                                    text={option.category.displayName || ''}
                                                    title={option.category.displayName || ''}
                                                    highlightText={searchQuery}
                                                    overflowEllipsis
                                                    fontWeight={option.selected ? 'bold' : 'regular'}
                                                    color='natural700Color'
                                                    variant="bodySmall"
                                                />
                                                <Text
                                                    color='natural700Color'
                                                    fontWeight={option.selected ? 'bold' : 'regular'}
                                                    variant="bodySmall">
                                                    ({option?.hits})
                                                </Text>
                                            </button>
                                        </li>
                                    )
                                    )}
                                </ul>

                            </AccordionItem>
                        )
                    })
                }
            </Accordion>
        </div>
    );
};
