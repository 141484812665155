import React from 'react';
import { Accordion, AccordionItem, ModuleHeader, Text, Loader } from 'ui';
import { LoadMore } from '~/components/features';
import { OrderHistoryList } from '../OrderHistoryList/OrderHistoryList';
import styles from './OrderHistory.module.css';
import { useTranslations } from '~/services/cms';
import { useUserInformation, useOrderHistory } from '../../hooks';


export const OrderHistory = () => {

    const translate = useTranslations();

    const {
        isLoading: isUserInfoLoading
    } = useUserInformation();

    const {
        items: orderHistoryItems,
        totalItems,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading: isLoadingOrderHistory,
        isFetching
    } = useOrderHistory({ skip: 0, take: 5 });

    return (
        <>
            <ModuleHeader headline={translate('profile.myOrders')} variant="display3" />
            <span className={styles.headerText}>
                <Text className={styles.headerDate} variant="display5">
                    {translate('profile.orderDate')}
                </Text>
                <Text variant="display5">{translate('profile.orderNumber')}</Text>
            </span>
            {isUserInfoLoading || isLoadingOrderHistory ? <div className={styles.resWrapper}>
                <Loader />
            </div> : null}
            {!isUserInfoLoading && !isLoadingOrderHistory ?
                <Accordion>
                    {orderHistoryItems?.map(order => {
                        return (
                            <AccordionItem key={order.orderNumber} header={
                                <span className={styles.orderHeader}>
                                    <Text variant="body" className={styles.headerDate}>
                                        {order.orderDate}
                                    </Text>
                                    <Text variant="body">{order.orderNumber}</Text>
                                </span>
                            }>
                                <OrderHistoryList {...order} />
                            </AccordionItem>
                        )
                    })}
                    <LoadMore
                        total={totalItems}
                        isFetching={isFetching && isFetchingNextPage}
                        loaded={orderHistoryItems?.length || 0}
                        hasNextPage={hasNextPage}
                        onClick={fetchNextPage} />
                </Accordion> : null}
        </>
    );
};
