import React, { FC, useState } from 'react'
import { CategoryHierarchyFacetResult } from '@relewise/client'
import Facets, { IFacetsProps } from '../Facets/Facets';
import styles from './MobileFilters.module.scss'
import { Overlay, Button, Spacer, Text } from 'ui';
import Sorting from '../Sorting/Sorting';
import { SortKeyType, SortOrderType } from '../hooks';
import { SidebarFilter } from '~/components/modules/M170SearchPage/components/SidebarFilter';
import useFacets from '../Facets/use-facets';
import { useTranslations } from '~/services/cms';


interface IProps extends IFacetsProps {
    sortBy: SortKeyType;
    sortOrder: SortOrderType
    onChangeSortBy: (val: SortKeyType) => void
    onChangeOrder: (val: SortOrderType) => void
    searchQuery?: string;
    facets: CategoryHierarchyFacetResult;
    isLicensePlateSearch?: boolean;
}


const MobileFilters: FC<IProps> = (props) => {

    const {
        relewiseFacets,
        sortBy = '',
        sortOrder = 'Ascending',
        onChangeOrder,
        onChangeSortBy,
        isFetching,
        isLoading,
        searchQuery,
        facets,
        isLicensePlateSearch
    } = props
    const [isOpen, setOpen] = useState(false);

    const { activeFacetsLength } = useFacets()
    const translate = useTranslations();

    return (
        <>
            <Overlay
                open={isOpen}
                onDismiss={() => setOpen(false)}
                variant="full"
                text={translate('accessibility.closeIconTitle')}
            >
                <div className={styles.filterRoot}>
                    {!isLicensePlateSearch && (
                        <>
                            <Text variant="body">
                                {translate('facets.filterOnCarModel')}
                            </Text>
                            <Spacer size='2' />
                            <Facets
                                relewiseFacets={relewiseFacets}
                                isFetching={isFetching}
                                isMobile />
                            <Spacer size='6' />
                        </>
                    )}
                    <Sorting
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                        onChangeSortBy={onChangeSortBy}
                        onChangeOrder={onChangeOrder}
                        isFetching={isFetching} />
                    <Spacer size='6' />
                    <SidebarFilter
                        isFetching={isFetching}
                        isLoading={isLoading}
                        searchQuery={searchQuery}
                        facets={facets} />
                </div>
            </Overlay>
            <div className={styles.stickySection}>
                <Button onClick={() => setOpen(true)}
                    size='large'
                    iconName='filter'
                    isFlexible
                >
                    {translate('facets.filters')}
                    {activeFacetsLength
                        ? ` (${activeFacetsLength} ${translate('facets.activeFacets')})`
                        : ''}
                </Button>
            </div>
        </>
    )
}

export default MobileFilters